// Generated by Framer (0f540f8)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, getPropertyControls, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as sharedStyle from "../css/GL_SiaOCQ";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["egeoelKkX", "PQMhhOc4e"];

const serializationHash = "framer-UB93z"

const variantClassNames = {egeoelKkX: "framer-v-55rcjy", PQMhhOc4e: "framer-v-jcex1a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "egeoelKkX", "Variant 2": "PQMhhOc4e"}

const getProps = ({height, id, name1, title, width, ...props}) => { return {...props, IeAFEWAAy: name1 ?? props.IeAFEWAAy ?? "House", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "egeoelKkX", xqvyP956i: title ?? props.xqvyP956i ?? "Full Name"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;name1?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, IeAFEWAAy, xqvyP956i, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "egeoelKkX", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-55rcjy", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"egeoelKkX"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-4f89ecfa-9ced-4828-96de-7dbbdb3044ba, rgb(204, 204, 204))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-b7e0ee39-7195-4e97-994f-cec289c7aee4, rgb(255, 255, 255))", borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, ...style}} {...addPropertyOverrides({PQMhhOc4e: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1tid2jw-container"} layoutDependency={layoutDependency} layoutId={"E3MotjoEa-container"} style={{opacity: 0.7}}><Phosphor color={"var(--token-c7b4806f-64e2-4921-ac45-46853e04999f, rgb(37, 37, 37))"} height={"100%"} iconSearch={"House"} iconSelection={IeAFEWAAy} id={"E3MotjoEa"} layoutId={"E3MotjoEa"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1yqhea4"} data-styles-preset={"GL_SiaOCQ"}>Full Name</motion.p></React.Fragment>} className={"framer-1j1q9jb"} data-framer-name={"Placeholder Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"I1452:7132;1441:8957"} style={{"--framer-paragraph-spacing": "0px", opacity: 0.7}} text={xqvyP956i} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UB93z.framer-c6in1u, .framer-UB93z .framer-c6in1u { display: block; }", ".framer-UB93z.framer-55rcjy { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 18px 22px 18px 22px; position: relative; width: 470px; }", ".framer-UB93z .framer-1tid2jw-container { flex: none; height: 20px; position: relative; width: 20px; }", ".framer-UB93z .framer-1j1q9jb { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UB93z.framer-55rcjy { gap: 0px; } .framer-UB93z.framer-55rcjy > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-UB93z.framer-55rcjy > :first-child { margin-left: 0px; } .framer-UB93z.framer-55rcjy > :last-child { margin-right: 0px; } }", ".framer-UB93z.framer-v-jcex1a.framer-55rcjy { align-content: flex-start; align-items: flex-start; height: 300px; }", ...sharedStyle.css, ".framer-UB93z[data-border=\"true\"]::after, .framer-UB93z [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 62
 * @framerIntrinsicWidth 470
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"PQMhhOc4e":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"IeAFEWAAy":"name1","xqvyP956i":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerc8pGM78En: React.ComponentType<Props> = withCSS(Component, css, "framer-UB93z") as typeof Component;
export default Framerc8pGM78En;

Framerc8pGM78En.displayName = "Other/Input Field";

Framerc8pGM78En.defaultProps = {height: 62, width: 470};

addPropertyControls(Framerc8pGM78En, {variant: {options: ["egeoelKkX", "PQMhhOc4e"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, IeAFEWAAy: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "House", description: undefined, hidden: undefined, title: "Name"}, xqvyP956i: {defaultValue: "Full Name", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerc8pGM78En, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})